// gatsby-browser.js
// ES6
import React from "react"
import "./src/sass/breakthru.css";

console.log('gatsby-browser');

export const onClientEntry = () => {
  window.onload = () => { 
    /* do stuff */ 
    if (typeof getValue !== "undefined"){
        memberchart.reflow();
        console.log('reflow');
    }

  }
}
  
export const onRouteUpdate = () => {
    console.log("onRouteUpdate"); // this works

	if (typeof membermap === "function") { 
	    membermap();
	}else{
        console.log('routeupdate  membermap not a function');
    }
    
	 if (typeof disgraph === "function") { 
	     disgraph();
	 }


  }


