exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-a-partner-js": () => import("./../../../src/pages/become-a-partner.js" /* webpackChunkName: "component---src-pages-become-a-partner-js" */),
  "component---src-pages-crises-directory-js": () => import("./../../../src/pages/crises-directory.js" /* webpackChunkName: "component---src-pages-crises-directory-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-how-we-declare-a-crisis-js": () => import("./../../../src/pages/how-we-declare-a-crisis.js" /* webpackChunkName: "component---src-pages-how-we-declare-a-crisis-js" */),
  "component---src-pages-how-we-work-js": () => import("./../../../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-isr-js": () => import("./../../../src/pages/join-isr.js" /* webpackChunkName: "component---src-pages-join-isr-js" */),
  "component---src-pages-media-center-js": () => import("./../../../src/pages/media-center.js" /* webpackChunkName: "component---src-pages-media-center-js" */),
  "component---src-pages-meet-our-partners-js": () => import("./../../../src/pages/meet-our-partners.js" /* webpackChunkName: "component---src-pages-meet-our-partners-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-open-source-proposals-js": () => import("./../../../src/pages/open-source-proposals.js" /* webpackChunkName: "component---src-pages-open-source-proposals-js" */),
  "component---src-pages-other-ways-to-help-js": () => import("./../../../src/pages/other-ways-to-help.js" /* webpackChunkName: "component---src-pages-other-ways-to-help-js" */),
  "component---src-pages-project-single-js": () => import("./../../../src/pages/project-single.js" /* webpackChunkName: "component---src-pages-project-single-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-science-community-js": () => import("./../../../src/pages/science-community.js" /* webpackChunkName: "component---src-pages-science-community-js" */),
  "component---src-pages-submit-proposal-intermediary-js": () => import("./../../../src/pages/submit-proposal-intermediary.js" /* webpackChunkName: "component---src-pages-submit-proposal-intermediary-js" */),
  "component---src-pages-the-new-york-academy-of-science-js": () => import("./../../../src/pages/the-new-york-academy-of-science.js" /* webpackChunkName: "component---src-pages-the-new-york-academy-of-science-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-blog-post-listing-author-js": () => import("./../../../src/templates/blog-post-listing-author.js" /* webpackChunkName: "component---src-templates-blog-post-listing-author-js" */),
  "component---src-templates-blog-post-listing-categories-js": () => import("./../../../src/templates/blog-post-listing-categories.js" /* webpackChunkName: "component---src-templates-blog-post-listing-categories-js" */),
  "component---src-templates-blog-post-listing-js": () => import("./../../../src/templates/blog-post-listing.js" /* webpackChunkName: "component---src-templates-blog-post-listing-js" */),
  "component---src-templates-blog-post-single-js": () => import("./../../../src/templates/blog-post-single.js" /* webpackChunkName: "component---src-templates-blog-post-single-js" */),
  "component---src-templates-crisis-post-single-js": () => import("./../../../src/templates/crisis-post-single.js" /* webpackChunkName: "component---src-templates-crisis-post-single-js" */),
  "component---src-templates-news-post-single-js": () => import("./../../../src/templates/news-post-single.js" /* webpackChunkName: "component---src-templates-news-post-single-js" */),
  "component---src-templates-what-learned-single-js": () => import("./../../../src/templates/what-learned-single.js" /* webpackChunkName: "component---src-templates-what-learned-single-js" */)
}

